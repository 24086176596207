body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e7f6ff;
}

* {
  font-family: 'Inter', system-ui, sans-serif;
}

.wrapper {
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  max-width: 1080px;
  margin: 50px auto;
}

header {
  border-bottom: 1px solid #eaeaea;

  img {
    vertical-align: text-bottom;
    margin-right: 15px;
  }

  h3,
  p {
    margin: 0;
  }
}

.content {
  h1 {
    margin: 0;
  }
}

.search-block {
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  padding: 0 15px;
  position: relative;

  .clear {
    position: absolute;
    top: 12px;
    right: 15px;
    width: 18px;
    height: 18px;
    background: transparent;
    padding: 0;
    border: none;
    border-radius: 8px;

    img {
      width: 100%;
    }
  }

  input {
    border: 0;
    padding: 12px;
    font-size: 16px;
    width: 250px;
  }
}

.button {
  background-color: #ffffff;
  padding: 0;
  border: none;
  border-radius: 8px;
  height: 32px;
  width: 32px;
  cursor: pointer;
}

.cart-empty {
  text-align: center;

  p {
    width: 280px;
    line-height: 24px;
  }

  .green-button {
    width: 245px;
    margin-top: 20px;

    &:hover {
      img {
        transform: rotate(180deg) translateX(3px);
      }
    }

    img {
      position: relative;
      top: 1px;
      transform: rotate(180deg);
      margin-right: 15px;
      transition: transform 0.15s ease-in-out;
    }
  }
}

@keyframes button-loading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.cart-total-block {
  ul {
    margin-bottom: 40px !important;

    li {
      display: flex;
      align-items: flex-end;
      margin-bottom: 20px;

      div {
        flex: 1;
        height: 1px;
        border-bottom: 1px dashed #dfdfdf;
        position: relative;
        top: -4px;
        margin: 0 7px;
      }
    }
  }

  .green-button {
    position: relative;

    &:disabled {
      animation: button-loading 0.6s ease-in-out infinite;
    }

    &:hover {
      img {
        transform: translateX(5px);
      }
    }

    img {
      position: absolute;
      right: 30px;
      top: 20px;
      transition: transform 0.15s ease-in-out;
    }
  }
}

.green-button {
  width: 100%;
  height: 55px;
  background: #9dd558;
  border-radius: 18px;
  border: 0;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.1s ease-in-out;

  &:disabled {
    background-color: #bebebe;
    cursor: default;
  }

  &:hover {
    background: lighten(#9dd558, 5%);
  }

  &:active {
    background: darken(#9dd558, 5%);
  }
}

.cart-item {
  border: 1px solid #f3f3f3;
  border-radius: 20px;
  overflow: hidden;
  padding: 20px;

  .cart-item-img {
    height: 70px;
    width: 70px;
    background-size: contain;
    background-position: 0 -3px;
    background-repeat: no-repeat;
    margin-right: 20px;
  }

  p {
    font-size: 16px;
    margin: 0;
  }

  b {
    font-size: 14px;
  }

  .remove-btn {
    opacity: 0.5;
    transition: opacity 0.15s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }
}
